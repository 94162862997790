<template>
  <v-footer
    tile
    height="54"
    class="font-weight-medium shadow-sm ma-4 rounded-lg"
    :color="$vuetify.theme.dark ? 'dark' : 'white'"
    :dark="$vuetify.theme.dark"
    :inset="true"
  >
    <v-col class="text-left" cols="12">
      <div class="d-flex flex-row justify-space-between align-center">
        <strong class="text--disabled">
          <img
            v-if="$vuetify.theme.dark"
            height="12"
            alt="Voy Logo"
            src="/img/logo/voy-dark.png"
            class="unselectable non-draggable"
          />
          <img
            v-else
            height="12"
            alt="Voy Logo"
            class="unselectable non-draggable"
            src="/img/logo/voy-light.png"
          />
        </strong>
        <v-btn
          text
          x-small
          class="text--disabled"
          target="_blank"
          href="mailto:contato@ticketme.app"
        >
          Contato
        </v-btn>
      </div>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
};
</script>
